<template>
    <div>
        <div class="card card-custom gutter-b">
            <div class="card-header">
                <div class="card-title">
                <h3 class="card-label">Profil Identitas Surveyor</h3>
                </div>
            </div>
            <div class="card-body">
                <!--begin::Example-->
                <div class="example mb-10">
                    <h4 class="mb-10 font-weight-bold text-dark">INFORMASI IDENTITAS SURVEYOR</h4>
                <!--begin::Input-->
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Nama</label>
                        <div class="col-lg-8">
                            <input type="text" disabled v-model="profil.name" class="form-control"/>
                        </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Username</label>
                        <div class="col-lg-8">
                            <input type="text" disabled v-model="profil.username" class="form-control"/>
                        </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Alamat Surat Elektronik</label>
                        <div class="col-lg-8">
                            <input type="text" disabled v-model="profil.email" class="form-control"/>
                        </div>
                </div>
                <div class="form-group row">
                    <label class="col-4">Pendidikan Terakhir</label>
                        <div class="col-3">
                            <b-form-select disabled v-model="profil.pendidikan_terakhir" :options="pendidikan" style="width: 150px;"></b-form-select>
                        </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Alamat</label>
                        <div class="col-lg-8">
                            <input type="text" disabled v-model="profil.alamat" class="form-control"/>
                        </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label"></label>
                        <div class="col-lg-8">
                            <div class="row">
                                <div class="col-xl-6">
                                    <!--begin::Input-->
                                    <div class="form-group">            
                                        <label>Provinsi</label>
                                            <b-form-select disabled v-model="profil.id_ptsp">
                                            <option
                                                v-for="(dataprovinsi, k) in provinsi"
                                                :key="k"
                                                :value="dataprovinsi.id"
                                            >
                                                {{ dataprovinsi.name }}
                                            </option>
                                            </b-form-select>
                                            {{profil.provinsi}}
                                            <!-- {{profil.provinsi.id}} -->
                                    </div>
                                     <!--end::Input-->            
                                    </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">No Telp</label>
                                    <div class="col-lg-8">
                                        <div class="row">
                                            <div class="col-xl-6">
                                                <!--begin::Input-->
                                                <div class="form-group">
                                                    <input type="number" disabled v-model="profil.no_hp" class="form-control" />
                                                </div>
                                                <!--end::Input-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Tanggal Lahir</label>
                                    <div class="col-lg-8">
                                        <b-form-datepicker id="example-datepicker" disabled v-model="profil.tanggal_lahir" class="mb-2"></b-form-datepicker>
                                        
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Tempat Lahir</label>
                                    <div class="col-lg-8">
                                        <input type="text" disabled v-model="profil.tempat_lahir" class="form-control"/>
                                        
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label"></label>
                                    <div class="col-lg-8">
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <!--begin::Input-->
                                                <div class="form-group">
                                                    <!-- <label>Upload Foto Tampak Depan Kantor</label>
                                                    <div></div>
                                                    <b-form-file v-model="profil.tampak_depan"  type="file" ref="file"></b-form-file> -->
                                                    <!-- <div class="custom-file">
                                                     <input type="file" class="custom-file-input" id="customFile"/>
                                                     <label class="custom-file-label" for="customFile">Choose file</label>
                                                    </div> -->
                                                </div>
                                                <b-img :src="profil.ijazah" style="height: 100px; width: 200px;" fluid alt="Foto"></b-img>
                                                <!--end::Input-->
                                            </div>
                                        </div>
                                        <!-- <div v-if="status.status_sistem === 'Buka'"> -->
                                            <button type="button" @click="getverifikasi(profil.id)" style="background-color: #030A8C;float: right; border-color: #030A8C" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4">Verifikasi</button>
                                        <button type="button" @click="$router.push('/pengaturan/verifikasiuser')" style="float: right; margin-right:10px;" class="btn btn-warning font-weight-bolder text-uppercase px-9 py-4">Kembali</button>
                                        <!-- </div> -->
                                       
                                        
                                    </div>
                                </div>
                </div>
                <!--end::Example-->
            </div>
        </div>
        <!-- formData.append('name', this.name);
                formData.append('username', this.username);
                formData.append('email', this.email);
                formData.append('id_ptsp', this.id_ptsp);
                formData.append('password', this.password);
                formData.append('tanggal_lahir', this.tanggal_lahir);
                formData.append('tempat_lahir', this.tempat_lahir);
                formData.append('ijazah', this.ijazah);
                formData.append('id_role', this.id_role);
                formData.append('name_role', this.name_role);
                formData.append('pendidikan_terakhir', this.pendidikan_terakhir);
                formData.append('no_hp', this.no_hp);
                formData.append('alamat', this.alamat);
                formData.append('bidang_ilmu', this.bidang_ilmu); -->
        </div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import Swal from "sweetalert2";
// @import "vue-select/src/scss/vue-select.scss";

export default {
  name: "Profil-PTSP",
  components: {vSelect},
  data() {
    return {
    idprofil: localStorage.getItem('idprofil'),
    user: JSON.parse(localStorage.getItem('user')),
    tanggal: localStorage.getItem('tanggal'),
    urlpenilaian: localStorage.getItem('baseapipenilaian'),
    url: localStorage.getItem('baseapi'),
    status:[],
    //   tabIndex: 0
       selectedprovinsi:"",
       provinsi:[{
          id:"",
          name:"",
      }],
      selectedkota:"",
      kota:[{
          id:"",
          name:"",
      }],
      toastCount:0,
      profil:[],
      pendidikan: [
         { value: null, text: 'Silahkan Pilih' },
         { value: 'SD/Sederajat', text: 'SD/Sederajat' },
         { value: 'SMP/Sederajat', text: 'SMP/Sederajat' },
         { value: 'SMA/Sederajat', text: 'SMA/Sederajat' },
         { value: 'D1', text: 'D1' },
         { value: 'D2', text: 'D2' },
         { value: 'D3', text: 'D3' },
         { value: 'D4', text: 'D4' },
         { value: 'S1', text: 'S1' },
         { value: 'S2', text: 'S2' },
         { value: 'S3', text: 'S3' },
       ],
       verifikasi:'verified',
    };
  },
//   computed: {
//     ...mapGetters(["layoutConfig"]),

//     config() {
//       return this.layoutConfig();
//     }
//   },
  mounted() {
    // set the tab from previous
    // this.setActivePreviousTab();

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profil Surveyor" }]);
    // this.getlocation()

    // this.$nextTick(() => {
    //   const hljs = this.$el.querySelectorAll(".hljs");
    //   hljs.forEach(hl => {
    //     hl.classList.remove("hljs");
    //     hl.classList.add(`language-${hl.classList[1]}`);
    //   });
    // });
  },
  methods: {
      getprovinsi(){
        axios.get(this.url+'/provinsis').then(response => {
            this.provinsi = response.data;
            // this.getkota(provinsi)
            // console.log(this)
            console.log(response)
        });
      },
      getverifikasi(id){
        axios
            .put(this.url+'/users/'+ id, {
            // _method: 'patch',
            status:this.verifikasi,
          })
          .then(response => {
            this.$bvToast.toast('Berhasil Diverifikasi', {
                title: `Success `,
                variant: `success`,
                solid: true
            })
            // this.loaddata()
            this.$router.push('/pengaturan/verifikasiuser')
            console.log(response)
            return response
          })
          .catch(error=>{
            this.$bvToast.toast('Gagal Diverifikasi', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            console.log(error)
            return error
            });
    },
    loaddata(){
        axios.get(this.url+`/users/`+ this.$route.params.id).then(response => {
            this.profil = response.data.data;
            console.log(response.data.data)
        });
    },
  },
  created(){
      this.getprovinsi();
      this.loaddata();
  }
};
</script>